<template>
  <div>
    <ConfirmationModal v-if="isCloseAccountModal" @close="isCloseAccountModal = false" :headerTxt="
      establishmentToDesable.isEnabled
        ? `Fermer le compte de ${establishmentToDesable.establishmentName}`
        : null
    " :modalMessage="
  establishmentToDesable.isEnabled
    ? `Le responsable de structure n’aura
    plus accès à son compte Lifebloom.Si besoin, vous pourrez réactiver son compte plus
    tard`
    : `Réactiver le compte de ${establishmentToDesable.responsableFirstName} ${establishmentToDesable.responsableLastName}`
" :ButtonConfirmationTxt="establishmentToDesable.isEnabled ? 'Fermer' : 'Réactiver'" @confirm="closeAccount" />
    <Modal v-if="openCreateModal" @close="openCreateModal = false" :className="'size-modal'">
      <div class="container-modal">
        <span class="font-title-modal">1. Informations de l’établissement</span>
        <div class="text-info">1. Informations du responsable de structure</div>
        <div class="flex">
          <Input v-model="establishment.responsableLastName" :labelClass="'flex'" :containerClass="'w-100'"
            title="Nom *" />
          <Input v-model="establishment.responsableFirstName" title="Prénom *" :labelClass="'flex'"
            :containerClass="'w-100'" />
        </div>
        <div class="flex">
          <Input v-model="establishment.responsablePhone" :labelClass="'flex'" :containerClass="'w-100'"
            title="Téléphone" />
          <Input v-model="establishment.responsableFunction" title="Fonction" :labelClass="'flex'"
            :containerClass="'w-100'" />
          <div>
            <Input v-model="establishment.responsableEmail" title="Adresse mail *" :type="'email'" :labelClass="'flex'"
              :containerClass="'w-100'" @input="errorMsgEmail = false"
              :inputClassName="errorMsgEmail ? 'border-red' : ''" />
            <span class="email-warning-txt" v-if="errorMsgEmail">Veuillez entrer un email valide</span>
          </div>
        </div>
        <div class="text-info">2. Informations de l'établissement</div>
        <div class="flex">
          <Input v-model="establishment.establishmentName" title="Nom de l'établissement *" :labelClass="'flex'"
            :containerClass="'w-100'" />
          <Input v-model="establishment.type" title="Type d'établissement" :labelClass="'flex'"
            :containerClass="'w-100'" />
          <Input v-model="establishment.establishmentSiret" title="SIRET" :labelClass="'flex'"
            :containerClass="'w-100'" />
        </div>
        <div class="flex">
          <Input v-model="establishment.streetNumber" title="Numéro" :labelClass="'flex'" :containerClass="'w-30'" />
          <Input v-model="establishment.street" title="Voie" :labelClass="'flex'" :containerClass="'w-50'" />
        </div>
        <div class="flex">
          <Input v-model="establishment.streetComplement" title="Précisions" :labelClass="'flex'"
            :containerClass="'w-50'" />
        </div>
        <div class="flex">
          <Input v-model="establishment.city" title="Ville" :labelClass="'flex'" :containerClass="'w-100'" />
          <Input v-model="establishment.postalCode" title="Code postal *" :labelClass="'flex'"
            :containerClass="'w-100'" />
          <Input v-model="establishment.countryCode" title="Pays *" :labelClass="'flex'" :containerClass="'w-100 '" />
        </div>
        <div class="flex">
          <Input v-model="establishment.comment" title="Commentaire" :labelClass="'flex'" :type="'textarea'"
            :containerClass="'w-100'" :inputClassName="'heigh-comment'" />
        </div>
        <div class="width-btn">
          <Button title="Sauvegarder" type="secondary" @click="saveEstablishment" :disabled="isReadyToSave" />
        </div>
      </div>
    </Modal>
    <Modal v-if="openSecondModal" @close="openSecondModal = false" :className="'size-modal'">
      <div class="container-oxilio-modal">
        <span class="font-title-modal">Gérer les Oxilios de Nom de l’établissement</span>
        <div class="flex-space-around mr-100">
          <div class="flex-column">
            <span class="title-table">Oxilios de l’Unité de la Marche de l’établissement</span>
            <table>
              <thead>
                <tr>
                  <th>Oxilio</th>
                  <th>Taille</th>
                  <th>IMEI</th>
                  <th>Retirer un Oxilio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(device, i) in establishementDevices" :key="i">
                  <td>{{ device.identifier ? device.identifier : "" }}</td>
                  <td>{{ sizes[device.size] }}</td>
                  <td>{{ device.imei ? device.imei : "" }}</td>
                  <td>
                    <div v-if="!device.isUsedByPatient" class="small-btn-container">
                      <Button title="Retirer" size="small" type="warning"
                        @click="openModalRemoveDeviceFunction(device, i)" />
                    </div>
                    <span v-else class="txt-device-patient">Associé à un patient</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-column">
            <span class="title-table">Oxilios disponibles en stock</span>
            <table>
              <thead>
                <tr>
                  <th>Oxilio</th>
                  <th>Taille</th>
                  <th>IMEI</th>
                  <th>Ajouter un Oxilio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(device, i) in AllDevices" :key="i">
                  <td>{{ device.identifier }}</td>
                  <td>{{ sizes[device.size] }}</td>
                  <td>{{ device.imei }}</td>
                  <td>
                    <div class="small-btn-container">
                      <Button title="Ajouter" size="small" type="secondary"
                        @click="addDeviceEstablishment(device, i)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="openCommentDetailModal" @close="openCommentDetailModal = false"
      className="size-modal container-comment-modal">
      <div class="container-modal comment-modal">
        <span class="font-title-modal">Commentaire</span>
        <Input v-model="establishment.comment" :labelClass="'flex'" :containerClass="'w-100 comment-input'" isDisabled
          type="textarea" />
      </div>
      <div class="w-10 mr-auto">
        <Button title="Fermer" type="secondary" size="small" @click="openCommentDetailModal = false" />
      </div>
    </Modal>
    <div class="buttons-container">
      <router-link :to="{ name: 'MonitoringEstablishments' }">
        <Button title="Tableaux" type="primary" :active="true" />
      </router-link>
      <router-link :to="{ name: 'MonitoringEstablishmentsCharts' }">
        <Button title="Graphs" type="primary" />
      </router-link>
    </div>

    <!-- <MonitoringDisplayBar :columns="columns" /> -->
    <div class="monitoring-table">
      <table aria-describedby="monitoring">
        <thead>
          <!-- <tr>
            <th
              v-for="(group, i) in displayColumns"
              :key="`cols-${i}`"
              :colspan="group.fields.length"
              class="group group-head"
            >
              {{ group.title }}
            </th>
          </tr> -->
          <tr>
            <template v-for="(group, i) in displayColumns">
              <template v-for="(col, j) in group.fields">
                <th :key="`col-${i}-${j}`" :class="{ sortable: col.sortable, group: j === 0 }"
                  @click="sort(group, col)">
                  {{ col.title }}
                </th>
              </template>
            </template>
          </tr>
        </thead>

        <tbody>
          <!-- <tr
            v-for="(establishment, i) in displayEstablishments"
            :key="`establishment-${i}`"
          >
            <template v-for="(group, j) in establishment">
              <MonitoringPatientCell
                v-for="(data, k, indexCell) in group"
                :key="`establishmentCell-${i + '' + j + '' + indexCell + '-' + data}`"
                :cellType="Object.keys(group)[indexCell]"
                :data="data"
                :cellTitle="displayColumns[j].fields[indexCell].title"
                :editable="displayColumns[j].fields[indexCell].editable"
                :class="{ separator: indexCell === 0 && j !== 0 }"
              />
            </template>
          </tr> -->
          <tr v-for="(establishment, i) in establishmentsList" :key="i">
            <td>{{ establishment.establishmentName }}</td>
            <td>{{ establishment.type }}</td>
            <td>
              <div v-if="establishment.comment" @click="openCommentDetail(establishment)" class="pointer">
                <span v-if="establishment.comment.length > 40">
                  {{ establishment.comment.slice(0, 40) + "..." }}
                </span>
                <span v-else>
                  {{ establishment.comment }}
                </span>
              </div>
            </td>
            <td>{{ establishment.establishmentSiret }}</td>
            <td>{{ establishment.address ? establishment.address.streetNumber : "" }}</td>
            <td>{{ establishment.address ? establishment.address.street : "" }}</td>
            <td>
              {{ establishment.address ? establishment.address.streetComplement : "" }}
            </td>
            <td>{{ establishment.address ? establishment.address.city : "" }}</td>
            <td>{{ establishment.address ? establishment.address.postalCode : "" }}</td>
            <td>{{ establishment.address ? establishment.address.countryCode : "" }}</td>
            <td>
              {{ establishment.responsableFirstName }}
              {{ establishment.responsableLastName }}
            </td>
            <td>{{ establishment.responsableFunction }}</td>
            <td>{{ establishment.responsablePhone }}</td>
            <td>{{ establishment.responsableEmail }}</td>
            <td>
              <div class="svg-container">
                <SVGEdit @click="editEstablishment(establishment)" />
              </div>
            </td>
            <td>
              <div class="margin-button">
                <Button clas title="Gérer" type="secondary" size="small"
                  @click="openOxilioModal(establishment.establishmentId)" />
              </div>
            </td>
            <td>
              <div>
                <Button :title="
                  establishment.isEnabled ? 'Fermer le compte' : 'Réactiver le compte'
                " :type="establishment.isEnabled ? 'primary' : 'delete'" size="small"
                  @click="openConfirmModal(establishment)" />
              </div>
            </td>
            <td>
              <div>
                <Button title="Supprimer le compte" type="delete-type" :disabled="true" size="small" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmationModal v-if="openModalRemoveDevice" @close="openModalRemoveDevice = false" headerTxt="Retirer un Oxilio"
      ButtonConfirmationTxt="Retirer cet Oxilio" @confirm="removeDeviceEstablishment"
      modalMessage="Souhaitez-vous vraiment retirer cet Oxilio ? Il ne fera plus partie de l’Unité de la Marche de d’établissement." />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Button from "@/components/Button"
// import MonitoringDisplayBar from "@/components/Monitoring/MonitoringDisplayBar"
// import MonitoringPatientCell from "./MonitoringPatientCell"
import SVGEdit from "@/assets/icons/edit.svg"
import Modal from "../../components/ConfirmationModal/Modal.vue"
import Input from "../../components/Forms/Fields/Input.vue"
import { isEmail } from "../../utils/validation"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "MonitoringEstablishements",
  components: {
    Button,
    SVGEdit,
    // MonitoringDisplayBar,
    // MonitoringPatientCell
    Modal,
    Input,
    ConfirmationModal
  },
  data() {
    return {
      openCommentDetailModal: false,
      deviceToRemove: {},
      indexDeviceToRemove: 0,
      openModalRemoveDevice: false,
      establishmentToDesable: {},
      isCloseAccountModal: false,
      establishmentId: 0,
      isEditMode: false,
      errorMsgEmail: false,
      establishment: {},
      establishementDevices: [],
      AllDevices: [],
      openSecondModal: false,
      openCreateModal: false,
      sizes: ["Taille 1", "Taille 2"],
      columns: {
        main: {
          name: "main",
          title: "",
          display: true,
          hidden: true,
          fields: [
            { title: "Dernière activité" },
            { title: "Dernier passage de l'expert Lifebloom" },
            { title: "Planifier un passage de l'expert Lifebloom" },
            { title: "Nom", sortable: true, name: "establishmentName", editable: true },
            { title: "Type", sortable: true },
            { title: "Commentaire", name: "commentary", editable: true }
          ]
        },
        area: {
          name: "area",
          title: "Zone",
          display: false,
          fields: [
            { title: "Pays", sortable: true, name: "country", editable: true },
            { title: "Agence(s)" },
            { title: "Département / Province" }
          ]
        },
        establishmentDetails: {
          name: "establishmentDetails",
          title: "Détails établissement",
          display: false,
          fields: [
            {
              title: "Nom d’établissement",
              sortable: true,
              name: "establishmentName",
              editable: true
            },
            {
              title: "Type d’établissement",
              sortable: true,
              name: "establishmentType",
              editable: true
            },
            { title: "Commentaire", sortable: true, name: "comment", editable: true },
            { title: "SIRET", sortable: true, name: "siret", editable: true },
            { title: "N°", name: "number", editable: true },
            { title: "Voie", name: "way", editable: true },
            { title: "Précisions", name: "precision", editable: true },
            { title: "Ville", sortable: true, name: "city", editable: true },
            { title: "Code Postal", sortable: true, name: "zipcode", editable: true },
            { title: "Pays", sortable: true, name: "country", editable: true },
            {
              title: "Nom du Responsable",
              sortable: true,
              name: "responsibleName",
              editable: true
            },
            {
              title: "Fonction du Responsable",
              sortable: true,
              name: "responsibleJob",
              editable: true
            },
            {
              title: "Téléphone du Responsable",
              sortable: true,
              name: "responsibleTel",
              editable: true
            },
            {
              title: "Mail du Responsable",
              sortable: true,
              name: "responsibleMail",
              editable: true
            },
            {
              title: "Editer la ligne",
              name: "edit"
            },
            {
              title: "Gérer Oxilios",
              name: "manage"
            },
            {
              title: "Fermeture Compte",
              name: "closeCompte"
            },
            {
              title: "Supprimer Compte",
              name: "deleteCompte"
            }
          ]
        },
        responsables: {
          name: "responsables",
          title: "Responsables Structure",
          display: false,
          fields: [{ title: "Responsables" }]
        },
        usagePatients: {
          name: "usagePatients",
          title: "Usage Patients (7 derniers jours)",
          display: false,
          fields: [
            { title: "Tx Jours d'usage", sortable: true, name: "usageDays" },
            {
              title: "Tps d'utilisation / Jour d'usage",
              sortable: true,
              name: "usageTime"
            },
            {
              title: "Tx Performance / jours d'usage",
              sortable: true,
              name: "performance"
            },
            { title: "Nombre de connexion / patient", sortable: true, name: "connection" }
          ]
        },
        usageHealers: {
          name: "usageHealers",
          title: "Usage Soignants (7 derniers jours)",
          display: false,
          fields: [
            {
              title: "Taux d'association de l'unité de la marche",
              sortable: true,
              name: "walkUnity"
            },
            { title: "Nombre de connexion équipe", sortable: true, name: "connection" },
            { title: "Nombre d'activités projetées", sortable: true, name: "activities" }
          ]
        },
        usageRehabilitatorsTeam: {
          name: "usageRehabilitatorsTeam",
          title: "Usage Équipe de réhabilitateurs",
          display: false,
          fields: [
            { title: "Nombre affichage profils / patients ces 7 derniers jours" },
            { title: "Tx des patients ayant de l’autorééducation prescrite." },
            { title: "Temps moyen Chgt Autorééduc de chaque patient ce dernier mois" },
            { title: "Tx patients ayant eu 1 séance rééduc digital ce mois ci" },
            { title: "Tx séances rééduc digitale utilisant le temps réel ce mois ci" }
          ]
        },
        usageAll: {
          name: "usageAll",
          title: "Usage toute l'Equipe",
          display: false,
          fields: [
            { title: "Temps équipe soignante passé en ligne ces 7 derniers jours" }
          ]
        },
        lifebloomTeam: {
          name: "lifebloomTeam",
          title: "Équipe Lifebloom",
          display: false,
          fields: [{ title: "Responsable Affaire" }, { title: "Expert Lifebloom" }]
        },
        coachings: {
          name: "coachings",
          title: "Programmation Coaching",
          display: false,
          fields: [
            { title: "Nb coachings effectués" },
            { title: "Dernier coaching" },
            { title: "Prochain coaching" },
            { title: "Type" },
            { title: "Ne plus proposer de coaching" }
          ]
        },
        equipments: {
          name: "equipments",
          title: "Matériel Alloué",
          display: false,
          fields: [
            { title: "Taille de l'unité de la marche" },
            { title: "Modifier l'Unité de la marche." },
            { title: "Deadline maintenance annuelle" }
          ]
        }
      },
      sortBy: {
        group: "main",
        key: "establishmentName",
        order: 1
      }
    }
  },
  async created() {
    this.initializeEstablishmentObj()
    await this.$store.dispatch("admin/getEstablishments")
    await this.$store.dispatch("admin/getDevices")
    this.AllDevices = this.devices.filter((el) => !el.isUsed)
  },
  methods: {
    openConfirmModal(establishment) {
      this.establishmentToDesable = establishment
      this.isCloseAccountModal = true
    },
    openCommentDetail(data) {
      this.establishment = { ...data }
      this.openCommentDetailModal = true
    },
    async closeAccount() {
      await this.$store.dispatch(
        "admin/closeAccountEstablishment",
        this.establishmentToDesable
      )
      this.isCloseAccountModal = false
    },
    initializeEstablishmentObj() {
      this.establishment = {
        responsableFirstName: "",
        responsableLastName: "",
        responsablePhone: "",
        responsableFunction: "",
        responsableEmail: "",
        establishmentName: "",
        type: "",
        establishmentSiret: "",
        streetNumber: "",
        street: "",
        streetComplement: "",
        city: "",
        postalCode: "",
        countryCode: "",
        comment: ""
      }
    },
    async openOxilioModal(id) {
      this.establishmentId = id
      this.openSecondModal = true
      this.establishementDevices = await this.$store.dispatch(
        "admin/getEtablishmentDevices",
        id
      )
      const idEstablishmentDevices = this.establishementDevices.map((el) => el.id)
      this.AllDevices = this.AllDevices.filter(
        (el) => !idEstablishmentDevices.includes(el.id)
      )
    },
    opnCreateModal() {
      this.initializeEstablishmentObj()
      this.isEditMode = false
      this.openCreateModal = true
    },
    editEstablishment(data) {
      this.isEditMode = true
      this.establishment = { ...data, ...data.address }
      this.openCreateModal = true
    },
    openModalRemoveDeviceFunction(device, i) {
      this.indexDeviceToRemove = i
      this.deviceToRemove = device
      this.openModalRemoveDevice = true
    },
    async removeDeviceEstablishment() {
      await this.$store.dispatch("admin/removeDeviceEstablishment", {
        establishmentId: this.establishmentId,
        device: this.deviceToRemove
      })
      this.AllDevices.push(this.deviceToRemove)
      this.establishementDevices.splice(this.indexDeviceToRemove, 1)
      this.openModalRemoveDevice = false
    },
    async addDeviceEstablishment(device, i) {
      await this.$store.dispatch("admin/addDeviceEstablishment", {
        establishmentId: this.establishmentId,
        device: device
      })
      this.AllDevices.splice(i, 1)
      this.establishementDevices.push(device)
    },
    async saveEstablishment() {
      if (isEmail(this.establishment.responsableEmail)) {
        if (this.isEditMode) {
          await this.$store.dispatch("admin/editEstablishment", this.establishment)
        } else {
          await this.$store.dispatch("admin/addEstablishment", this.establishment)
        }
        this.openCreateModal = false
      } else {
        this.errorMsgEmail = true
      }
    },
    sort(group, column) {
      if (column.sortable) {
        const { name } = column

        if (this.sortBy.key === name) {
          this.sortBy.order = -this.sortBy.order
        } else {
          this.sortBy = {
            group: group.name,
            key: name,
            order: -1
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      establishmentsList: "admin/establishments",
      devices: "admin/devices"
    }),
    isReadyToSave() {
      return (
        !this.establishment.establishmentName ||
        !this.establishment.responsableLastName ||
        !this.establishment.responsableFirstName ||
        !this.establishment.responsableEmail ||
        !this.establishment.postalCode ||
        !this.establishment.countryCode
      )
    },
    displayColumns() {
      const cols = []
      Object.keys(this.columns).forEach((group) => {
        // if (this.columns[group].display) {
        //   cols.push(this.columns[group])
        // }
        if (this.columns[group].name === "establishmentDetails") {
          cols.push(this.columns[group])
        }
      })
      return cols
    },
    establishments() {
      return [
        {
          establishmentDetails: {
            siret: "1",
            zipcode: "10",
            city: "15",
            streetNumber: "",
            street: "",
            precision: ""
          }
        }
      ]
      // return this.establishmentsList.map((establishment) => {
      //   return {
      //     main: {
      //       lastActivity: "",
      //       lastExpertAction: "",
      //       setNextExpertAction: "",
      //       name: establishment.name,
      //       type: this.$t(`establishmentType.${establishment.type}`),
      //       commentary: ""
      //     },
      //     area: {
      //       country: "",
      //       agencies: [],
      //       department: ""
      //     },
      //     establishmentDetails: {
      //       siret: "",
      //       zipcode: "",
      //       city: "",
      //       streetNumber: "",
      //       street: "",
      //       precision: "",
      //       id: establishment.establishmentId
      //     },
      //     responsables: {
      //       responsables: ""
      //     },
      //     usagePatients: {
      //       usageDaysPercent:
      //         (establishment.computedStats.usageDaysPercentLast7Days
      //           ? establishment.computedStats.usageDaysPercentLast7Days
      //           : 0) + "%",
      //       usageTime:
      //         (establishment.computedStats.usageLast7Days
      //           ? establishment.computedStats.usageLast7Days
      //           : 0) + "h",
      //       performance:
      //         (establishment.computedStats.performancePercentLast7Days
      //           ? establishment.computedStats.performancePercentLast7Days
      //           : 0) + "%",
      //       connection: ""
      //     },
      //     usageHealers: {
      //       walkUnity: "",
      //       connection: "",
      //       activities: ""
      //     },
      //     usageRehabilitatorsTeam: {
      //       profilDisplays: "",
      //       selfWorkoutPatients: "",
      //       selfWorkoutPatientTime: "",
      //       rehabilitatedPatientsPercent: "",
      //       realTimeSelfworkoutPercent: ""
      //     },
      //     usageAll: {
      //       timeOnline: ""
      //     },
      //     lifebloomTeam: {
      //       responsable: {
      //         firstname: "Firstname",
      //         lastname: "Lastname"
      //       },
      //       expert: {
      //         firstname: "Firstname",
      //         lastname: "Lastname"
      //       }
      //     },
      //     coachings: {
      //       coachingsCount: "",
      //       lastCoaching: "",
      //       nextCoaching: "",
      //       coachingType: "",
      //       coachingAdvice: ""
      //     },
      //     equipments: {
      //       unitWalkSize: "",
      //       editUnitWalk: "",
      //       deadline: ""
      //     }
      //   }
      // })
    },
    sortedEstablishments() {
      const order = this.sortBy.order
      const group = this.sortBy.group
      const sortBy = this.sortBy.key

      return [...this.establishments].sort((a, b) => {
        return a[group][sortBy] > b[group][sortBy] ? 1 * order : -1 * order
      })
    },
    displayEstablishments() {
      return this.sortedEstablishments.map((establishment) => {
        const establishmentsCols = []
        Object.keys(establishment).forEach((group) => {
          if (group == "main" || this.columns[group].display) {
            establishmentsCols.push(establishment[group])
          }
        })
        return establishmentsCols
      })
    }
  },
  watch: {
    "columns.usagePatients.display"(displayUsage) {
      if (displayUsage) {
        this.$store.dispatch("admin/getEstablishmentsStats")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.txt-device-patient {
  font-style: italic;
  font-weight: 420;
  font-size: 0.875rem;
}

.buttons-container {
  margin-top: 24px;
  display: flex;

  a {
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.monitoring-table {
  display: inline-block;
  overflow: scroll;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 25px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .group {
      border-left: 2px solid $white-color;

      &:first-child {
        border-left: none;
      }
    }

    .group-head {
      font-size: 1.2rem;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
    vertical-align: bottom;
  }

  td {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  .separator {
    border-left: 2px solid $main-color;
  }
}

.button-container {
  width: 248px;

  margin-top: 20px !important;
}

.margin-button {
  margin: 10px 0px;
}

.svg-container {
  width: 28px;
  margin: auto 16px;
  margin-top: 5px;
  cursor: pointer;
}

.container-modal {
  text-align: center;
  padding: 0 200px;
}

.container-oxilio-modal {
  text-align: center;
}

.font-title-modal {
  font-size: 18px;
}

.text-info {
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 420;
  font-size: 22px;
  line-height: 24px;
  margin-top: 25px;
  align-items: center;
  color: #0c2298;
}

.container-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.width-btn {
  width: 176px;
  margin: 0 auto;
  margin-top: 20px;
}

.small-btn-container {
  width: 98px;
  margin: 8px auto;
}

.title-table {
  color: #0c2298;
  margin-bottom: 20px;
  font-size: 18px;
}

.email-warning-txt {
  font-size: 15px;
  color: red;
}
</style>
